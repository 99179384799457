import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", { key: "CurrContainerTypeCode", attrs: { "label": "Container Type", "reference": "cf.common.cont-types", "source": "code", "search-by": "Code", "placeholder": "Choose Container Type" } }), _c("select-filter", { key: "CurrItemcode", attrs: { "label": "Item Code", "reference": "cf.common.item-masters", "source": "itemCode", "search-by": "ItemCode", "placeholder": "Choose Item Code" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterProductionLine"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterProductionLine = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-production-line" }, [_c("resource", { attrs: { "name": "cf.common.cont-types", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.common.item-masters", "api-url": _vm.apiUrl, "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "name": "cf.production-lines", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/production-line" } }, [_c("filter-production-line")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FilterProductionLine
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
